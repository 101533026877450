export const languages: any[] = [
  {
    key: 'en',
    label: 'English',
    i18n: 'en_US'
  },
  {
    key: 'zh',
    label: '中文',
    i18n: 'zh_CN'
  },
];

export const districts: any[] = [
  {
    key: "hk",
    en: "Hong Kong",
    zh: "Hong Kong",
    children: [
      {
        key: "all",
        en: "ALL",
        zh: "ALL",
        children: [],
      },
      {
        key: "hk",
        en: "Hong Kong Island",
        zh: "香港島",
        children: [
          {

            "en": "Shau Kei Wan",
            "zh": "筲箕灣",
            "key": "shau_kei_wan"
          }, {

            "en": "Quarry Bay",
            "zh": "鰂魚涌",
            "key": "quarry_bay"
          }, {

            "en": "Shek O",
            "zh": "石澳",
            "key": "shek_o"
          }, {

            "en": "Repulse Bay",
            "zh": "淺水灣",
            "key": "repulse_bay"
          }, {

            "en": "Tin Wan",
            "zh": "田灣",
            "key": "tin_wan"
          }, {

            "en": "Ap Lei Chau",
            "zh": "鴨脷洲",
            "key": "ap_lei_chau"
          }, {

            "en": "Chai Wan",
            "zh": "柴灣",
            "key": "chai_wan"
          }, {

            "en": "Sheung Wan",
            "zh": "上環",
            "key": "sheung_wan"
          }, {

            "en": "Wong Chuk Hang",
            "zh": "黃竹坑",
            "key": "wong_chuk_hang"
          }, {

            "en": "Causeway Bay",
            "zh": "銅鑼灣",
            "key": "causeway_bay"
          }, {

            "en": "Sai Ying Pun",
            "zh": "西營盤",
            "key": "sai_ying_pun"
          }, {

            "en": "Pok Fu Lam",
            "zh": "薄扶林",
            "key": "pok_fu_lam"
          }, {

            "en": "Shek Tong Tsui",
            "zh": "石塘咀",
            "key": "shek_tong_tsui"
          }, {

            "en": "Sai Wan Ho",
            "zh": "西灣河",
            "key": "sai_wan_ho"
          }, {

            "en": "Tai Hang",
            "zh": "大坑",
            "key": "tai_hang"
          }, {

            "en": "Mid-Levels",
            "zh": "半山",
            "key": "mid-levels"
          }, {

            "en": "Tin Hau",
            "zh": "天后",
            "key": "tin_hau"
          }, {

            "en": "Happy Valley",
            "zh": "跑馬地",
            "key": "happy_valley"
          }, {

            "en": "North Point",
            "zh": "北角",
            "key": "north_point"
          }, {

            "en": "Aberdeen",
            "zh": "香港仔",
            "key": "aberdeen"
          }, {

            "en": "Kennedy Town",
            "zh": "堅尼地城",
            "key": "kennedy_town"
          }, {

            "en": "Wan Chai",
            "zh": "灣仔",
            "key": "wan_chai"
          }],
      },
      {
        key: "ke",
        en: "Kowloon East",
        zh: "九龍東",
        children: [{

          "en": "Ngau Tau Kok",
          "zh": "牛頭角",
          "key": "ngau_tau_kok"
        }, {

          "en": "San Po Kong",
          "zh": "新蒲崗",
          "key": "san_po_kong"
        }, {

          "en": "Wong Tai Sin",
          "zh": "黃大仙",
          "key": "wong_tai_sin"
        }, {

          "en": "Yau Tong",
          "zh": "油塘",
          "key": "yau_tong"
        }, {

          "en": "Kwun Tong",
          "zh": "觀塘",
          "key": "kwun_tong"
        }, {

          "en": "Kowloon Bay",
          "zh": "九龍灣",
          "key": "kowloon_bay"
        }],
      },
      {
        key: "kw",
        en: "Kowloon West",
        zh: "九龍西",
        children: [{

          "en": "Cheung Sha Wan",
          "zh": "長沙灣",
          "key": "cheung_sha_wan"
        }, {
    
          "en": "Lai Chi Kok",
          "zh": "荔枝角",
          "key": "lai_chi_kok"
        }, {
    
          "en": "To Kwa Wan",
          "zh": "土瓜灣",
          "key": "to_kwa_wan"
        }, {
    
          "en": "Hung Hom",
          "zh": "紅磡",
          "key": "hung_hom"
        }, {
    
          "en": "Tai Kok Tsui",
          "zh": "大角咀",
          "key": "tai_kok_tsui"
        }, {
    
          "en": "Jordan",
          "zh": "佐敦",
          "key": "jordan"
        }, {
    
          "en": "Prince Edward",
          "zh": "太子",
          "key": "prince_edward"
        }, {
    
          "en": "Yau Ma Tei",
          "zh": "油麻地",
          "key": "yau_ma_tei"
        }, {
    
          "en": "Sham Shui Po",
          "zh": "深水埗",
          "key": "sham_shui_po"
        }, {
    
          "en": "Ho Man Tin",
          "zh": "何文田",
          "key": "ho_man_tin"
        }, {
    
          "en": "Kowloon City",
          "zh": "九龍城",
          "key": "kowloon_city"
        }, {
    
          "en": "Mong Kok",
          "zh": "旺角",
          "key": "mong_kok"
        }],
      },
      {
        key: "ne",
        en: "New Territories East",
        zh: "新界東",
        children: [{

          "en": "Sai Kung",
          "zh": "西貢",
          "key": "sai_kung"
        }, {
    
          "en": "Tseung Kwan O",
          "zh": "將軍澳",
          "key": "tseung_kwan_o"
        }, {
    
          "en": "Sha Tin",
          "zh": "沙田",
          "key": "sha_tin"
        }, {
    
          "en": "Sha Tau Kok",
          "zh": "沙頭角",
          "key": "sha_tau_kok"
        }, {
    
          "en": "Ma On Shan",
          "zh": "馬鞍山",
          "key": "ma_on_shan"
        }, {
    
          "en": "Lok Ma Chau",
          "zh": "落馬州",
          "key": "lok_ma_chau"
        }, {
    
          "en": "Fo Tan",
          "zh": "火炭",
          "key": "fo_tan"
        }, {
    
          "en": "Kwu Tung",
          "zh": "古洞",
          "key": "kwu_tung"
        }, {
    
          "en": "Tai Po",
          "zh": "大埔",
          "key": "tai_po"
        }, {
    
          "en": "Fanling",
          "zh": "粉嶺",
          "key": "fanling"
        }, {
    
          "en": "Tai Wai",
          "zh": "大圍",
          "key": "tai_wai"
        }, {
    
          "en": "Sheung Shui",
          "zh": "上水",
          "key": "sheung_shui"
        }],
      },
      {
        key: "nw",
        en: "New Territories West",
        zh: "新界西",
        children: [{

          "en": "Kam Tin",
          "zh": "錦田",
          "key": "kam_tin"
        }, {
    
          "en": "Tsuen Wan",
          "zh": "荃灣",
          "key": "tsuen_wan"
        }, {
    
          "en": "Kwai Chung",
          "zh": "葵涌",
          "key": "kwai_chung"
        }, {
    
          "en": "Lau Fau Shan",
          "zh": "流浮山",
          "key": "lau_fau_shan"
        }, {
    
          "en": "Tin Shui Wai",
          "zh": "天水圍",
          "key": "tin_shui_wai"
        }, {
    
          "en": "Yuen Long",
          "zh": "元朗",
          "key": "yuen_long"
        }, {
    
          "en": "Tsing Yi",
          "zh": "青衣",
          "key": "tsing_yi"
        }, {
    
          "en": "Ngong Shuen Chau",
          "zh": "昂船洲",
          "key": "ngong_shuen_chau"
        }, {
    
          "en": "Tuen Mun",
          "zh": "屯門",
          "key": "tuen_mun"
        }],
      },
    ],
  },
  {
    key: "sg",
    en: "Singapore",
    zh: "Singapore",
    children: [
      {
        key: "all",
        en: "ALL",
        zh: "ALL",
        children: [],
      },
      {
        key: "ce",
        en: "Central",
        zh: "Central",
        children: [
          {
            en: "Kallang",
            zh: "Kallang",
            key: "Kallang",
          },
          {
            en: "Macpherson",
            zh: "Macpherson",
            key: "Macpherson",
          },
          {
            en: "Braddell",
            zh: "Braddell",
            key: "Braddell",
          },
          {
            en: "Marina",
            zh: "Marina",
            key: "Marina",
          },
          {
            en: "Raffles Place",
            zh: "Raffles Place",
            key: "Raffles Place",
          },
          {
            en: "Chinatown",
            zh: "Chinatown",
            key: "Chinatown",
          },
          {
            en: "Orchard",
            zh: "Orchard",
            key: "Orchard",
          },
          {
            en: "River Valley",
            zh: "River Valley",
            key: "River Valley",
          },
          {
            en: "Harbourfront",
            zh: "Harbourfront",
            key: "Harbourfront",
          },
          {
            en: "Telok Blangah",
            zh: "Telok Blangah",
            key: "Telok Blangah",
          },
          {
            en: "Queenstown",
            zh: "Queenstown",
            key: "Queenstown",
          },
          {
            en: "Bukit Merah",
            zh: "Bukit Merah",
            key: "Bukit Merah",
          },
          {
            en: "Novena",
            zh: "Novena",
            key: "Novena",
          },
          {
            en: "Thomson",
            zh: "Thomson",
            key: "Thomson",
          },
          {
            en: "Bishan",
            zh: "Bishan",
            key: "Bishan",
          },
          {
            en: "Ang Mo Kio",
            zh: "Ang Mo Kio",
            key: "Ang Mo Kio",
          },
          {
            en: "Toa Payoh",
            zh: "Toa Payoh",
            key: "Toa Payoh",
          },
          {
            en: "Balestier",
            zh: "Balestier",
            key: "Balestier",
          },
          {
            en: "Bendemeer",
            zh: "Bendemeer",
            key: "Bendemeer",
          },
          {
            en: "Seletar",
            zh: "Seletar",
            key: "Seletar",
          },
          {
            en: "Upper Thomson",
            zh: "Upper Thomson",
            key: "Upper Thomson",
          },
          {
            en: "Mandai Road",
            zh: "Mandai Road",
            ke: "Mandai Road",
          },
        ],
      },
      {
        key: "we",
        en: "West",
        zh: "West",
        children: [
          {
            en: "Bukit Timah",
            zh: "Bukit Timah",
            key: "Bukit Timah",
          },
          {
            en: "Holland Road",
            zh: "Holland Road",
            key: "Holland Road",
          },
          {
            en: "Buona Vista",
            zh: "Buona Vista",
            key: "Buona Vista",
          },
          {
            en: "West Coast",
            zh: "West Coast",
            key: "West Coast",
          },
          {
            en: "Clementi",
            zh: "Clementi",
            key: "Clementi",
          },
          {
            en: "Toh Guan",
            zh: "Toh Guan",
            key: "Toh Guan",
          },
          {
            en: "Jurong East",
            zh: "Jurong East",
            key: "Jurong East",
          },
          {
            en: "Ulu Pandan",
            zh: "Ulu Pandan",
            key: "Ulu Pandan",
          },
          {
            en: "Bukit Batok",
            zh: "Bukit Batok",
            key: "Bukit Batok",
          },
          {
            en: "Choa Chu Kang",
            zh: "Choa Chu Kang",
            key: "Choa Chu Kang",
          },
          {
            en: "Tuas",
            zh: "Tuas",
            key: "Tuas",
          },
          {
            en: "Jurong West",
            zh: "Jurong West",
            ke: "Jurong West",
          },
        ],
      },
      {
        key: "ea",
        en: "East",
        zh: "East",
        children: [
          {
            en: "Geylang",
            zh: "Geylang",
            key: "Geylang",
          },
          {
            en: "Ubi",
            zh: "Ubi",
            key: "Ubi",
          },
          {
            en: "Kaki Bukit",
            zh: "Kaki Bukit",
            key: "Kaki Bukit",
          },
          {
            en: "East Coast",
            zh: "East Coast",
            key: "East Coast",
          },
          {
            en: "Marine Parade",
            zh: "Marine Parade",
            key: "Marine Parade",
          },
          {
            en: "Bedok",
            zh: "Bedok",
            key: "Bedok",
          },
          {
            en: "Upper East Coast",
            zh: "Upper East Coast",
            key: "Upper East Coast",
          },
          {
            en: "Pasir Ris",
            zh: "Pasir Ris",
            key: "Pasir Ris",
          },
          {
            en: "Tampines",
            zh: "Tampines",
            key: "Tampines",
          },
          {
            en: "Changi",
            zh: "Changi",
            key: "Changi",
          },
          {
            en: "Loyang",
            zh: "Loyang",
            ke: "Loyang",
          },
        ],
      },
      {
        key: "ne",
        en: "North-East",
        zh: "North-East",
        children: [
          {
            en: "Jalan Besar",
            zh: "Jalan Besar",
            key: "Jalan Besar",
          },
          {
            en: "Little India",
            zh: "Little India",
            key: "Little India",
          },
          {
            en: "Serangoon",
            zh: "Serangoon",
            key: "Serangoon",
          },
          {
            en: "Hougang",
            zh: "Hougang",
            key: "Hougang",
          },
          {
            en: "Punggol",
            zh: "Punggol",
            ke: "Punggol",
          },
        ],
      },
      {
        key: "no",
        en: "North",
        zh: "North",
        children: [
          {
            en: "Yishun",
            zh: "Yishun",
            key: "Yishun",
          },
          {
            en: "Sembawang",
            zh: "Sembawang",
            key: "Sembawang",
          },
          {
            en: "Woodlands",
            zh: "Woodlands",
            key: "Woodlands",
          },
          {
            en: "Kranji",
            zh: "Kranji",
            key: "Kranji",
          },
          {
            en: "Marsiling",
            zh: "Marsiling",
            ke: "Marsiling",
          },
        ],
      },
    ],
  },
];

export const vehicleTypes = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'private',
    label: 'Private',
  },
  {
    key: 'commercial',
    label: 'Commercial',
  },
];

export const paymentStatus = [
  {
    key: 'due',
    label: 'Due'
  },
  {
    key: 'payment_processed',
    label: 'Payment Processed'
  },
  {
    key: 'payment_completed',
    label: 'Payment Completed'
  }
]