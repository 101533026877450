// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import { IState } from "~stores/index";
import {
  IHttpProps,
  IHttpReturn,
  IHttpRequestError,
  defaultHttpRequestError,
} from "./types";
import { post } from "./base";

export default (props: IHttpProps): IHttpReturn => {
  const { url = "", initialOptions = { headers: {} } } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IHttpRequestError>(
    defaultHttpRequestError
  );
  const [options, setOptions] = useState(initialOptions);
  // const session = useSelector((state: IState) => state.session);

  const _post = async (data: any | null, callback: Function | undefined) => {
    try {
      setLoading(true);
      setError(defaultHttpRequestError);

      const req = await post(url, data, options);

      if (req) {
        if (callback) {
          callback(req.data);
        }
      }
    } catch (errors) {
      if (errors.response) {
        setError(errors.response.data);
        if (callback) callback(errors.response.data);
      } else if (errors.message) {
        setError({
          error: {
            message: errors.message,
          },
        });
        if (callback) callback(errors.message);
      } else {
        setError({
          error: {
            message: "Unidentified Error",
          },
        });
        if (callback) callback(error);
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (session?.access_token && session?.refresh_token) {
  //     setOptions((prevState) => ({
  //       ...prevState,
  //       headers: {
  //         ...prevState.headers,
  //         "X-Access-Token": session.access_token,
  //       },
  //     }));
  //   }
  // }, [session]);

  return { loading, error, setOptions, post: _post };
};
