import { lazy } from "react";
import {
  StarOutlined,
  SettingOutlined,
  GlobalOutlined,
  ShopOutlined,
  UnorderedListOutlined,
  ShoppingCartOutlined,
  CommentOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";

export interface IRoutes {
  label: string;
  key: string;
  showInMenu?: boolean;
  path?: string;
  icon?: any;
  component?: any;
  exact?: boolean;
  children?: IRoutes[];
}

const Offer = lazy(() => import("~modules/offer"));
const Banner = lazy(() => import("~modules/banners"));
const Promo = lazy(() => import("~modules/promos"));
const Setting = lazy(() => import("~modules/settings"));
const User = lazy(() => import("~modules/user"));
const Shop = lazy(() => import("~modules/shops"));
const Sku = lazy(() => import("~modules/skus"));
const ServiceOption = lazy(() => import("~modules/service-options"));
const Service = lazy(() => import("~modules/services"));
const Supplier = lazy(() => import("~modules/suppliers"));
const Brand = lazy(() => import("~modules/brands"));
const Suggestion = lazy(() => import("~modules/suggestions"));
const PricedOrder = lazy(() => import("~modules/orders/pricedOrder"));
const BundleOrder = lazy(() => import("~modules/orders/bundleOrder"));
const OfferOrder = lazy(() => import("~modules/orders/offerOrder"));
const UnpricedOrder = lazy(() => import("~modules/orders/unpricedOrder"));
const CarOwner = lazy(() => import("~modules/car-owners"));
const Mechanic = lazy(() => import("~modules/mechanics"));
const CarModel = lazy(() => import("~modules/car-models"));
const RatingAutoShop = lazy(() => import("~modules/rating-auto-shops"));
const RatingCarOwner = lazy(() => import("~modules/rating-car-owners"));
const Payment = lazy(() => import("~modules/payments"));
const Refund = lazy(() => import("~modules/refunds"));
const Feedback = lazy(() => import("~modules/feedbacks"));
const Bundles = lazy(() => import("~modules/bundles"));
const Subscription = lazy(() => import("~modules/subscription"));
const RoadsideServiceProvider = lazy(() => import("~modules/roadsideserviceproviders"));

const routes: IRoutes[] = [
  {
    label: "Following",
    key: "following",
    icon: StarOutlined,
  },
  {
    label: "Bookings",
    key: "bookings",
    icon: ShoppingCartOutlined,
    children: [
      {
        label: "Priced",
        key: "priced-orders",
        path: "/priced-orders",
        exact: false,
        component: PricedOrder,
      },
      {
        label: "Bundle",
        key: "bundle-orders",
        path: "/bundle-orders",
        exact: false,
        component: BundleOrder,
      },
      {
        label: "Offer",
        key: "offer-orders",
        path: "/offer-orders",
        exact: false,
        component: OfferOrder,
      },
      {
        label: "Unpriced",
        key: "unpriced-orders",
        path: "/unpriced-orders",
        exact: false,
        component: UnpricedOrder,
      }
    ],
  },
  {
    label: "Purchases",
    key: "purchases",
    icon: ShopOutlined,
    children:[
      {
        label: "Bundles Order",
        key: "subscriptions",
        path: "/subscriptions",
        exact: false,
        component: Subscription,
      }
    ]
  },
  {
    label: "Payments",
    key: "payments",
    icon: CreditCardOutlined,
    children:[
      {
        label: "Payment",
        key: "payments",
        path: "/payments",
        exact: false,
        component: Payment,
      },
      {
        label: "Refund",
        key: "refunds",
        path: "/refunds",
        exact: false,
        component: Refund,
      },
    ]
  },
  {
    label: "Feedback",
    key: "feedback",
    icon: CommentOutlined,
    children: [
      {
        label: "Rating Auto Shops",
        key: "rating-auto-shops",
        path: "/rating-auto-shops",
        exact: false,
        component: RatingAutoShop,
      },
      {
        label: "Rating Car Owners",
        key: "rating-car-owners",
        path: "/rating-car-owners",
        exact: false,
        component: RatingCarOwner,
      },
      {
        label: "Feedbacks",
        key: "feedbacks",
        path: "/feedbacks",
        exact: false,
        component: Feedback,
      },
    ],
  },
  {
    label: "Publish",
    key: "publish",
    icon: GlobalOutlined,
    children: [
      {
        label: "Offer",
        key: "offer",
        path: "/offers",
        exact: false,
        component: Offer,
      },
      {
        label: "Banner",
        key: "banner",
        path: "/banners",
        exact: false,
        component: Banner,
      },
      {
        label: "Promos",
        key: "promos",
        path: "/promos",
        exact: false,
        component: Promo,
      },
      {
        label: "Bundles",
        key: "bundles",
        path: "/bundles",
        exact: false,
        component: Bundles,
      }
    ],
  },
  {
    label: "Base",
    key: "base",
    icon: ShopOutlined,
    children: [
      {
        label: "Roadside Providers",
        key: "roadsideserviceproviders",
        path: "/roadsideserviceproviders",
        exact: false,
        component: RoadsideServiceProvider,
      },
      {
        label: "Suppliers",
        key: "suppliers",
        path: "/suppliers",
        exact: false,
        component: Supplier,
      },
      {
        label: "Auto shops",
        key: "shops",
        path: "/shops",
        exact: false,
        component: Shop,
      },
      {
        label: "Mechanic",
        key: "mechanic",
        path: "/mechanics",
        component: Mechanic,
        exact: false,
      },
      {
        label: "Car Owner",
        key: "car-owner",
        path: "/car-owners",
        component: CarOwner,
        exact: false,
      },
      {
        label: "Car Model",
        key: "car-model",
        path: "/car-models",
        exact: false,
        component: CarModel,
      }
    ],
  },
  {
    label: "Dataset",
    key: "dataset",
    icon: UnorderedListOutlined,
    children: [
      {
        label: "Service",
        key: "services",
        path: "/services",
        exact: false,
        component: Service,
      },
      {
        label: "Service Options",
        key: "serviceoptions",
        path: "/service-options",
        exact: false,
        component: ServiceOption,
      },
      {
        label: "SKU",
        key: "skus",
        path: "/skus",
        exact: false,
        component: Sku,
      },
      {
        label: "Brands",
        key: "brands",
        path: "/brands",
        exact: false,
        component: Brand,
      },
      {
        label: "Suggestions",
        key: "suggestions",
        path: "/suggestions",
        exact: false,
        component: Suggestion,
      },
    ],
  },
  {
    label: "Settings",
    key: "settings",
    icon: SettingOutlined,
    children: [
      {
        label: "Settings",
        key: "settings",
        path: "/settings",
        showInMenu: false,
        exact: false,
        component: Setting,
      },
      {
        label: "Admin Right",
        key: "admin-right",
        path: "/settings/admin-right",
      },
    ],
  },
  {
    label: "User",
    key: "users",
    path: "/users",
    exact: false,
    showInMenu: false,
    component: User,
  },
];

export default routes;
